<template>
  <div :class="`flex flex-col h-full text-base flex-grow border-b-2 border-gray-300
     ${item.id ? '' : 'cursor-pointer'}`"
    :data-testid="`container-space-${drawer.index}-${column.index}-${item.index}`" @click="() =>
      markSpaceAsSelected(
        drawer.index,
        column.index,
        item.index,
        isSelectedSpace
      )
      ">
    <div :class="`flex flex-grow flex-col transition-all delay-200 duration-2000 ${matching ? 'matching' : ''
      }`" :data-order-id="item.id" :data-space-index="item.index" :data-column-index="column.index"
      :data-drawer-index="drawer.index" :data-total-items="item.totalItems"
      :data-testid="`space-${drawer.index}-${column.index}-${item.index}`">
      <div :class="`py-1 px-2 pb-5 relative min-height-60 ${statusClass()} flex-grow ${item.totalItems > 0 && 'pb-0'
        }`">
        <div class="
            text-lg text-left
            absolute
            top-0
            left-0
            p-1
            pl-0.5
            font-bold
            identifier
            underline
          ">
          {{ columnLetter }}{{ item.index }}
        </div>
        <!-- <div class="text-sm text-left absolute top-0 right-0 p-1 pt-1.5">
          ({{ sizeName }})
        </div> -->
        <div class="text-sm text-left absolute bottom-0 right-0 p-1">
          <span v-if="item.totalItems > 0">{{ item.items }}/{{ item.totalItems }} -
            {{ percentageOfItems() }}%</span>
          ({{ sizeName }})
        </div>
        <div class="w-full float-left mb-1">
          <div class="text-lg text-right font-bold pl-8 -mr-1">
            {{ orderId() }}
            <div class="absolute bottom-0.5 left-1 cursor-pointer">
              <font-awesome-icon icon="trash-alt" size="xs"
                v-if="(item.totalItems === 0 || !item.totalItems) && !item.id" @click.stop="() => deleteSpace(drawer.index, column.index, item.index)
                  " :data-testid="`delete-space-${drawer.index}-${column.index}-${item.index}`" />
              <font-awesome-icon icon="eraser" size="xs" v-else
                :data-testid="`empty-space-${drawer.index}-${column.index}-${item.index}`" @click.stop="() => emptySpace(drawer.index, column.index, item.index)
                  " />
            </div>
          </div>
          <!-- <div class="text-sm" v-if="item.totalItems > 0">
            C: {{ relativeTimeStarted }}
            <div class="text-xs">LI: {{ relativeTimeModified }}</div>
          </div> -->
          <div class="text-sm" v-if="item.totalItems > 0">
            <span v-if="item.lineItems && item.lineItems.length > 0"
              class="relative mr-2 cursor-pointer info-tooltip-trigger">
              <font-awesome-icon icon="info-circle" size="lg" />
              <div class="info-tooltip">
                <b>Line item IDs: </b><br />
                {{ item.lineItems.join(', ') }}
              </div>
            </span>
            <b>C: {{ minutesTimeStarted() }}h</b>
            <span class="text-xs"> - LI: {{ minutesTimeModified() }}h</span>
            <select class="badge focus:outline-none" v-model="item.badge" @change="changeBadge">
              <option selected value="-">---</option>
              <option v-for="label in labels" :value="label" :key="label">
                {{ label }}
              </option>
            </select>
          </div>
          <div v-if="item.totalItems > 0">
            <div>
              <div v-if="item.totalItems <= 6">
                <span v-for="n in item.totalItems" :key="n" :class="`lineitem transition-all delay-300 duration-500 ${n <= item.items ? 'bg-green-600' : 'bg-red-600'
                  }`"></span>
              </div>
              <div class="
                  bg-red-600
                  mx-10
                  rounded
                  relative
                  mt-2
                  border border-white
                  progress-bar
                " v-else>
                <div class="
                    absolute
                    top-0
                    bottom-0
                    right-0
                    left-0
                    rounded
                    bg-green-600
                    transition-all
                  " :style="`width: calc(${(item.items / item.totalItems) * 100
                    }%)`"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="m-2" v-if="matching">
          <font-awesome-icon icon="check" class="cursor-pointer text-green-600" size="lg" @click.stop="() => addLineItemToSpace(drawer.index, column.index, item.index)
            " />
          <font-awesome-icon icon="times" class="cursor-pointer ml-2 text-red-600" size="lg" @click.stop="() =>
            addLineItemToSpace(drawer.index, column.index, item.index, true)
            " />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { letterForNumber } from "@/assets/general.js";

export default {
  name: "Space",
  props: ["item", "column", "drawer"],
  data: () => {
    return {
      matching: false,
    };
  },
  watch: {
    item: {
      handler() {
        this.matching = this.item.matching;
      },
      deep: true,
    },
  },
  computed: {
    labels() {
      return this.$store.state.labels;
    },
    isSelectedSpace() {
      let isIt = false;

      if (!this.selectedSpace) return isIt;

      const matches = this.selectedSpace.match(/([0-9]+)-([0-9]+)-([0-9]+)/);
      if (matches && matches.length === 4) {
        if (
          this.drawer.index == matches[1] &&
          this.column.index == matches[2] &&
          this.item.index == matches[3]
        ) {
          isIt = true;
        } else {
          isIt = false;
        }
      }

      return isIt;
    },
    sizeName() {
      if (this.item?.size === 1) return "S";
      else if (this.item?.size === 2) return "M";
      else if (this.item?.size === 4) return "L";
      return "not set";
    },
    relativeTimeStarted() {
      return this.$dayjs(this.item.startedDate).fromNow();
    },

    relativeTimeModified() {
      return this.$dayjs(this.item.modifiedDate).fromNow();
    },
    daysSinceCreation() {
      const now = this.$dayjs(new Date());
      const started = this.$dayjs(this.item.startedDate);
      return now.diff(started, "day");
    },
    columnLetter() {
      if (this.drawer.index > 1) return `${this.drawer.index}.${letterForNumber(this.column.index)}`
      else
        return letterForNumber(this.column.index);
    },
    selectedSpace() {
      return this.$store.state.selectedSpace;
    },
  },
  methods: {
    minutesTimeStarted() {
      const now = this.$dayjs(new Date());
      const started = this.$dayjs(this.item.startedDate);
      const hours = now.diff(started, "hour");

      return `${Math.trunc(hours / 24)}d ${hours % 24}`;
    },
    minutesTimeModified() {
      const now = this.$dayjs(new Date());
      const modified = this.$dayjs(this.item.modifiedDate);
      const hours = now.diff(modified, "hour");

      return `${Math.trunc(hours / 24)}d ${hours % 24}`;
    },
    deleteSpace(drawerIndex, columnIndex, spaceIndex) {
      this.$store.dispatch("deleteSpace", {
        drawerIndex,
        columnIndex,
        spaceIndex,
      });
    },
    addLineItemToSpace(drawerIndex, columnIndex, spaceIndex, abort = false) {
      const lineItemId = localStorage.getItem('lastLineItemId')

      this.$store.dispatch("addLineItemToSpace", {
        drawerIndex,
        columnIndex,
        spaceIndex,
        abort,
        lineItemId
      });
    },
    emptySpace(drawerIndex, columnIndex, spaceIndex) {
      this.$store.dispatch("emptySpace", {
        drawerIndex,
        columnIndex,
        spaceIndex,
      });
    },
    orderId() {
      return this.item.id || "Empty";
    },
    percentageOfItems() {
      return Math.round(this.ratioOfItems() * 100);
    },
    ratioOfItems() {
      if (this.item?.totalItems === 0) return 0;

      return this.item?.items / this.item?.totalItems;
    },
    isFull() {
      return this.ratioOfItems() === 1 && this.item?.totalItems > 0;
    },
    statusClass() {
      if (this.isFull()) return "bg-green-500 text-white";

      if (this.daysSinceCreation >= 3) return "bg-red-500 text-white";
      else if (this.daysSinceCreation >= 1) return "bg-yellow-600 text-white";
      else if (this.daysSinceCreation >= 0 && this.item.totalItems > 0)
        return "bg-yellow-400 text-white";
      else if (this.isSelectedSpace) return "bg-gray-400 text-white";
      return "bg-white text-black";
    },
    markSpaceAsSelected(drawerIndex, columnIndex, spaceIndex, deselect) {
      if (this.item.id) return;
      this.$store.dispatch("markSpaceAsSelected", {
        drawerIndex,
        columnIndex,
        spaceIndex,
        deselect,
      });
    },
    changeBadge(e) {
      // console.log(e.target.value);
      this.$store.dispatch("setSpaceBadge", {
        drawerIndex: this.drawer.index,
        columnIndex: this.column.index,
        spaceIndex: this.item.index,
        option: e.target.value,
      });
    },
  },
  mounted() {
    if (this.item.matching) this.matching = true;

    this.$parent?.$parent?.$parent?.$on(
      "forceUpdate",
      (drawerIndex, columnIndex, spaceIndex) => {
        // console.log(
        //   "forceUpdate",
        //   drawerIndex,
        //   "-",
        //   columnIndex,
        //   "-",
        //   spaceIndex
        // );
        if (
          drawerIndex === this.drawer.index &&
          columnIndex === this.column.index &&
          spaceIndex === this.item.index
        ) {
          // console.log(
          //   "forcingUpdate on",
          //   this.drawer.index,
          //   "-",
          //   this.column.index,
          //   "-",
          //   this.item.index
          // );
          this.$forceUpdate();
        }
      }
    );

    setInterval(() => {
      this.$forceUpdate();
    }, 30000);
  },
};
</script>

<style lang="scss" scoped>
.box {
  min-width: 50%;
  max-width: 100%;
  flex-grow: 1;
  min-height: 50px;
}

.stripes-bg {
  background: repeating-linear-gradient(45deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 10px,
      rgba(0, 0, 0, 0.2) 10px,
      rgba(0, 0, 0, 0.2) 20px);

  // background: repeating-linear-gradient(
  //   45deg,
  //   #ccc,
  //   #ccc 10px,
  //   #ababab 10px,
  //   #ababab 20px
  // );
}

.lineitem {
  width: 15px;
  height: 15px;
  border: 1px solid white;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  margin-top: 3px;
}

.matching {
  // transform: scale(1.2);
  // z-index: 2;
  // box-shadow: 6px 6px 5px -1px rgba(0, 0, 0, 0.44);
  // border-radius: 5px;

  >* {
    // border-radius: 5px;
    background: blue !important;
  }
}

.min-height-60 {
  min-height: 60px;
}

.identifier {
  font-size: 17px;
  line-height: 20px;
  color: black;
  font-weight: bold !important;
}

.progress-bar {
  height: 15px;
}

select.badge {
  width: 70px;
  background: black;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  text-align: center;
  color: white;
}

.info-tooltip {
  display: none;
  position: absolute;
  top: 25px;
  left: 0px;
  background: black;
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  min-width: 150px;
  z-index: 1;
}

.info-tooltip-trigger {
  &:hover {
    .info-tooltip {
      display: block;
    }
  }
}
</style>
