import Vuex from "vuex";
import Vue from "vue";
import {
  createFlash,
  getServerId,
  getStoragePrefix,
  letterForNumber,
  playCompleted,
  playError,
  playSuccess,
} from "./general";
import axios from "axios";
import { defaultDrawers } from "./consts";

Vue.use(Vuex);

export const myFindIndex = (collection, index) => {
  return collection.findIndex((drawer) => drawer.index == index);
};

export const updateDrawerData = (state, place) => {
  console.log("updateDrawerData", state, place);
  const token = localStorage.getItem(`${getStoragePrefix()}/token`);

  if (token) {
    myStore.commit("toggleUpdatingRn", { bln: true });
    axios
      .post(
        `/server/${getServerId()}/drawers`,
        { drawers: state.drawers },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          // console.log("got data for server " + getServerId());
          myStore.commit("setLoggedIn", {
            token: localStorage.getItem(`${getStoragePrefix()}/token`),
            refreshToken: localStorage.getItem(
              `${getStoragePrefix()}/refreshToken`
            ),
            username: localStorage.getItem(`${getStoragePrefix()}/username`),
          });
          myStore.commit("toggleLoginModalOpen", false);

          if (response.data.drawers) state.drawers = response.data.drawers;
          setTimeout(() => {
            myStore.commit("toggleUpdatingRn", { bln: false });
          }, 1000);
        }
      })
      .catch((e) => {
        setTimeout(() => {
          myStore.commit("toggleUpdatingRn", { bln: false });
        }, 1000);
        console.log(e);
      });
  }
};

export const getDrawerData = (state) => {
  const token = localStorage.getItem(`${getStoragePrefix()}/token`);

  if (token)
    axios
      .get(`/server/${getServerId()}/drawers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data) {
          // console.log("got data for server " + getServerId());
          myStore.commit("setLoggedIn", {
            token: localStorage.getItem(`${getStoragePrefix()}/token`),
            refreshToken: localStorage.getItem(
              `${getStoragePrefix()}/refreshToken`
            ),
            username: localStorage.getItem(`${getStoragePrefix()}/username`),
          });
          myStore.commit("toggleLoginModalOpen", false);

          if (response.data.drawers) {
            // console.log("updating drawers");
            state.drawers = response.data.drawers;
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
};

export const getLabels = (state) => {
  const token = localStorage.getItem(`${getStoragePrefix()}/token`);

  if (token)
    axios
      .get(`/labels`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data) {
          // console.log("got data for server " + getServerId());

          state.labels = response.data;
        }
      })
      .catch((e) => {
        console.log(e);
      });
};

export const getQueue = (state) => {
  axios
    .get(`/queue`, {
      headers: {},
    })
    .then((response) => {
      if (response.data) {
        // console.log("got data for server " + getServerId());

        state.queue = response.data;
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const clearQueue = () => {
  axios
    .get(`/clear-queue`, {
      headers: {},
    })
    .then((response) => {
      if (response.data) {
        // console.log("got data for server " + getServerId());
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const deleteItemFromQueueCall = (id) => {
  axios
    .delete(`/queue?id=${id}`)
    .then((response) => {
      if (response.data) {
        // console.log("got data for server " + getServerId());
        console.log("deleted ", response.data);
      }
    })
    .catch(() => {
      // console.log(e)
    });
};

export const updateLabelsData = (state) => {
  const token = localStorage.getItem(`${getStoragePrefix()}/token`);

  if (token) {
    axios
      .post(
        `/labels`,
        { labels: state.labels },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          // console.log("got data for server " + getServerId());
          if (response.data.labels) state.labels = response.data.labels;
        }
      })
      .catch(() => {
        // console.log(e)
      });
  }
};

const updateLocalStorage = (store) => {
  // called when the store is initialized
  store.subscribe((mutation, state) => {
    // called after every mutation.
    // The mutation comes in the format of `{ type, payload }`.
    if (
      localStorage.getItem(`${getStoragePrefix()}/drawers`) !==
      JSON.stringify(state.drawers)
    ) {
      state.previousStorage = JSON.parse(
        localStorage.getItem(`${getStoragePrefix()}/drawers`)
      );
      localStorage.setItem(
        `${getStoragePrefix()}/drawers`,
        JSON.stringify(state.drawers)
      );
    }
  });
};

export const mutations = {
  updateDrawers(state, drawers) {
    // console.log("update drawers");
    // console.log(drawers);
    if (!state.updatingRn) state.drawers = drawers;
  },
  addDrawer(state) {
    const length = state.drawers.length;
    state.drawers.push({
      index: length + 1,
      columns: [],
    });
    updateDrawerData(state, 'add drawer');
  },
  deleteDrawer(state, index) {
    const foundIndex = myFindIndex(state.drawers, index);
    if (foundIndex > -1) {
      state.drawers.splice(foundIndex, 1);
      this?.commit("reorderDrawers");
    }
  },
  reorderDrawers(state) {
    state.drawers = state.drawers.map((drawer, index) => ({
      ...drawer,
      index: index + 1,
    }));
    updateDrawerData(state, 'reorderDrawers');
  },
  init(state) {
    const drawers = localStorage.getItem(`${getStoragePrefix()}/drawers`);
    const stats = localStorage.getItem(`${getStoragePrefix()}/stats`);

    getDrawerData(state);
    getLabels(state);
    getQueue(state);

    if (drawers) {
      state.drawers = JSON.parse(drawers);
    }
    if (stats) {
      state.stats = JSON.parse(stats);
    }
  },
  addColumn(state, drawerIndex) {
    const foundIndex = myFindIndex(state.drawers, drawerIndex);

    if (foundIndex > -1) {
      const length = state.drawers[foundIndex].columns.length;
      state.drawers[foundIndex].columns.push({
        index: length + 1,
        spaces: [],
      });
    }
    updateDrawerData(state, 'addcolumn');
  },
  deleteColumn(state, { drawerIndex, columnIndex }) {
    const foundDrawerIndex = myFindIndex(state.drawers, drawerIndex);

    if (foundDrawerIndex > -1) {
      const drawer = state.drawers[foundDrawerIndex];

      const foundColumnIndex = myFindIndex(drawer.columns, columnIndex);

      if (foundColumnIndex > -1) {
        drawer.columns.splice(foundColumnIndex, 1);
        this?.commit("reorderColumns", drawerIndex);
      }
    }
  },
  reorderColumns(state, drawerIndex) {
    const foundDrawerIndex = myFindIndex(state.drawers, drawerIndex);

    if (foundDrawerIndex > -1) {
      state.drawers[foundDrawerIndex].columns = state.drawers[
        foundDrawerIndex
      ].columns.map((column, index) => ({
        ...column,
        index: index + 1,
      }));
      updateDrawerData(state, 'reordercolumns');
    }
  },
  addSpace(state, { drawerIndex, columnIndex, size }) {
    // console.log(`drawerIndex: ${drawerIndex}, columnIndex ${columnIndex}`);
    const foundDrawer = myFindIndex(state.drawers, drawerIndex);

    if (foundDrawer > -1) {
      const drawer = state.drawers[foundDrawer];

      const foundColumn = myFindIndex(drawer.columns, columnIndex);

      if (foundColumn > -1) {
        const length = drawer.columns[foundColumn].spaces.length;
        drawer.columns[foundColumn].spaces.push({
          index: length + 1,
          size: size,
          totalItems: 0,
          items: 0,
          matching: false,
          badge: "-",
        });
      }
      updateDrawerData(state, 'addspace');
    }
  },
  deleteSpace(state, { drawerIndex, columnIndex, spaceIndex, id }) {
    // console.log(
    //   `drawerIndex: ${drawerIndex}, columnIndex ${columnIndex}, spaceIndex: ${spaceIndex}`
    // );

    if (id) {
      const spaceIndex = state.drawers?.[0]?.randomStackItems.findIndex(
        (sp) => sp.id === id
      );
      if (spaceIndex >= 0) {
        state.drawers?.[0]?.randomStackItems.splice(spaceIndex, 1);
        updateDrawerData(state, 'deletespace');
      }
    } else {
      const foundDrawer = myFindIndex(state.drawers, drawerIndex);
      if (foundDrawer > -1) {
        const drawer = state.drawers[foundDrawer];

        const foundColumn = myFindIndex(drawer.columns, columnIndex);

        if (foundColumn > -1) {
          const column = drawer.columns[foundColumn];

          const foundSpace = myFindIndex(column.spaces, spaceIndex);
          if (foundSpace > -1) {
            column.spaces.splice(foundSpace, 1);
            this?.commit("reorderSpaces", { drawerIndex, columnIndex });
          }
        }
      }
    }
  },
  reorderSpaces(state, { drawerIndex, columnIndex }) {
    const foundDrawerIndex = myFindIndex(state.drawers, drawerIndex);

    if (foundDrawerIndex > -1) {
      const drawer = state.drawers[foundDrawerIndex];

      const foundColumnIndex = myFindIndex(drawer.columns, columnIndex);

      if (foundColumnIndex > -1) {
        // console.log("foundColumn reorderSpaces");
        drawer.columns[foundColumnIndex].spaces = drawer.columns[
          foundColumnIndex
        ].spaces.map((space, index) => ({ ...space, index: index + 1 }));
        updateDrawerData(state, 'reorderspaces');
      }
    }
  },
  emptySpace(state, { drawerIndex, columnIndex, spaceIndex }) {
    let space =
      state.drawers[drawerIndex - 1]?.columns[columnIndex - 1]?.spaces[
      spaceIndex - 1
      ];
    // console.log(`emptying space: ${drawerIndex}-${columnIndex}-${spaceIndex}`);
    // console.log(space);
    if (space) {
      space.items = 0;
      space.totalItems = 0;
      space.id = null;
      space.matching = false;
      space.startedDate = null;
      space.modifiedDate = null;
      space.badge = "-";
      if (space.lineItems) space.lineItems = null;
      //   this.$emit("forceUpdate", drawerIndex, columnIndex, spaceIndex);
      updateDrawerData(state, 'emptyspace');
    } else {
      // console.log("space not found");
    }
  },
  addLineItemToSpace(
    state,
    { drawerIndex, columnIndex, spaceIndex, abort, id, lineItemId }
  ) {
    let space;
    if (!columnIndex || !spaceIndex) {
      space = state.drawers?.[0]?.randomStackItems.find((sp) => sp.id === id);

      // console.log("scrolling");
      setTimeout(
        () =>
          document.querySelector(`[data-order-id="${id}"]`)?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          }),
        200
      );
    } else
      space =
        state.drawers[drawerIndex - 1]?.columns[columnIndex - 1]?.spaces[
        spaceIndex - 1
        ];

    if (abort) {
      space.matching = false;
      // console.log('addlineitemtospace updatedrawerdata')
      updateDrawerData(state, 'addlineitemtospace if abort');
      return;
    }

    if (space) {
      if (space.items === 0)
        deleteItemFromQueueCall(space.id.replace(/-.*$/, "").replace(/^u/, ""));

      // console.log('addlineitemtospace updatedrawerdata')
      // updateDrawerData(state, 'addlineitemtospace if space');
      if (space.items < space.totalItems) {
        space.items += 1;

        if (!space.lineItems) space.lineItems = [];
        if (lineItemId) space.lineItems.push(lineItemId)

        space.modifiedDate = Vue.prototype
          ?.$dayjs()
          .format("YYYY-MM-DD HH:mm:ss");

        if (space.items === space.totalItems) {
          createFlash("green");
          playCompleted();
          this?.commit("changeStats", {
            lineItems: 1,
            orders: 1,
          });
        } else {
          createFlash("green");
          playSuccess();
          this?.commit("changeStats", {
            lineItems: 1,
            orders: 0,
          });
        }
        state.lastSpace = `${letterForNumber(
          parseInt(columnIndex)
        )}${spaceIndex}`;
        // TODO need to avoid calling an action from a mutation
        this?.dispatch("setSpaceNonMatching", {
          drawerIndex,
          columnIndex,
          spaceIndex,
          id,
        });
        //   space.matching = false;
        //   setTimeout(() => (space.matching = false), 400);
      } else {
        playError();
        setTimeout(() => {
          state.errorText = "space is already full";
          createFlash("red");
          this.commit("toggleErrorModalOpen");
          this.dispatch("abortAny", { drawerIndex, columnIndex, spaceIndex });
        }, 300);
      }
      // console.log('addlineitemtospace updatedrawerdata')
      // updateDrawerData(state, 'add lineitemtospace end');
    } else {
      playError();
      setTimeout(() => {
        state.errorText = "space not found!";
        createFlash("red");
        this.commit("toggleErrorModalOpen");
      }, 300);
    }
  },
  setSpaceNonMatching(state, { drawerIndex, columnIndex, spaceIndex, id }) {
    let space;
    if (!columnIndex || !spaceIndex) {
      space = state.drawers[0].randomStackItems.find((sp) => sp.id === id);
    } else
      space =
        state.drawers[drawerIndex - 1]?.columns[columnIndex - 1]?.spaces[
        spaceIndex - 1
        ];

    if (space) {
      // console.log("setSpaceNonMatching");
      space.matching = false;
      updateDrawerData(state, 'setspacenonmatching');
    }
  },
  toggleErrorModalOpen(state, bool) {
    // console.log("toggleErrorModalOpen ", bool);
    if (bool === true || bool === false) state.errorModalOpen = bool;
    else state.errorModalOpen = !state.errorModalOpen;
  },
  toggleLoginModalOpen(state, bool) {
    // console.log("toggleLoginModalOpen ", bool);
    if (bool === true || bool === false) state.loginModalOpen = bool;
    else state.loginModalOpen = !state.loginModalOpen;
  },
  clearAllMatching(state) {
    // console.log("clear all matching");
    state.drawers.forEach((drawer) => {
      drawer.columns.forEach((column) => {
        column.spaces.forEach((space) => {
          if (space.matching) space.matching = false;
        });
      });
    });
    // console.log('clear all matching update drawer data')
    // updateDrawerData(state);
  },
  undo(state) {
    if (state.previousStorage) {
      state.drawers = state.previousStorage;
      localStorage.setItem(
        `${getStoragePrefix()}/drawers`,
        JSON.stringify(state.previousStorage)
      );
      state.previousStorage = null;
    }
    if (state.previousStats) {
      state.stats = state.previousStats;
      localStorage.setItem(
        `${getStoragePrefix()}/stats`,
        JSON.stringify(state.previousStats)
      );
      state.previousStats = null;
    }
    updateDrawerData(state, 'undo');
  },
  changeErrorText(state, text) {
    state.errorText = text;
  },
  clearAllCompleted(state) {
    state.drawers.forEach((drawer, drawerIndex) => {
      drawer.columns.forEach((column, columnIndex) => {
        column.spaces.forEach((space, spaceIndex) => {
          if (space.items === space.totalItems)
            this.commit("emptySpace", {
              drawerIndex: drawerIndex + 1,
              columnIndex: columnIndex + 1,
              spaceIndex: spaceIndex + 1,
            });
        });
      });

      drawer.randomStackItems?.forEach((item) => {
        if (item.items === item.totalItems) {
          this.commit("deleteSpace", {
            id: item.id,
          });
        }
      });
    });
    updateDrawerData(state, 'clearallcompleted');
  },
  changeStats(state, { lineItems = 0, orders = 0 }) {
    const today = Vue.prototype.$dayjs().format("YYYY-MM-DD");
    // console.log("change stats: ");
    // console.log(today);
    // console.log(lineItems, orders);
    state.previousStats = JSON.parse(
      localStorage.getItem(`${getStoragePrefix()}/stats`)
    );
    if (today === state.stats.date) {
      state.stats.lineItemsAdded += lineItems;
      state.stats.ordersCompleted += orders;
    } else {
      state.stats.date = today;
      state.stats = {
        ...state.stats,
        lineItemsAdded: lineItems,
        ordersCompleted: orders,
      };
    }
    localStorage.setItem(
      `${getStoragePrefix()}/stats`,
      JSON.stringify(state.stats)
    );

    // console.log(state.stats);
  },
  setLoggedIn(state, { username, token, refreshToken }) {
    state.loggedIn = username;
    state.token = token;
    state.refreshToken = refreshToken;
    state.username = username;
    localStorage.setItem(`${getStoragePrefix()}/token`, token);
    localStorage.setItem(`${getStoragePrefix()}/refreshToken`, refreshToken);
    localStorage.setItem(`${getStoragePrefix()}/username`, username);
  },
  markAsMatching(
    state,
    { id, totalItems, drawerIndex, columnIndex, spaceIndex }
  ) {
    // console.log('mark as matching')
    if (!spaceIndex || !columnIndex) {
      if (
        state.drawers[drawerIndex - 1] &&
        !state.drawers[drawerIndex - 1].randomStackItems
      ) {
        state.drawers[drawerIndex - 1].randomStackItems = [];
      }
      // console.log(`pushing to drawer ${drawerIndex - 1}`);

      const spaceIndex = state.drawers[
        drawerIndex - 1
      ].randomStackItems.findIndex((item) => item.id === id);
      if (spaceIndex >= 0) {
        state.drawers[drawerIndex - 1].randomStackItems[
          spaceIndex
        ].matching = true;
        // console.log('matching: true!!, ', state.drawers[drawerIndex - 1].randomStackItems[
        //   spaceIndex
        // ])
        setTimeout(
          () =>
            document.querySelector(`[data-order-id="${id}"]`)?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            }),
          500
        );

        updateDrawerData(state, 'markasmatching stack');
      } else {
        // console.log('2222matching: true!!, ', state.drawers[drawerIndex - 1].randomStackItems[
        //   spaceIndex
        // ])
        state.drawers[drawerIndex - 1].randomStackItems.push({
          id,
          totalItems,
          items: 0,
          matching: true,
          startedDate: Vue.prototype.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
          modifiedDate: Vue.prototype.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        // console.log("scrolling");
        setTimeout(
          () =>
            document.querySelector(`[data-order-id="${id}"]`)?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            }),
          500
        );
      }
    } else {
      // console.log('33333 matching: true!!, ', state.drawers[drawerIndex - 1].columns[columnIndex - 1].spaces[
      //   spaceIndex - 1
      // ])
      state.drawers[drawerIndex - 1].columns[columnIndex - 1].spaces[
        spaceIndex - 1
      ] = {
        ...state.drawers[drawerIndex - 1].columns[columnIndex - 1].spaces[
        spaceIndex - 1
        ],
        id,
        totalItems,
        index: spaceIndex,
        items: 0,
        matching: true,
        startedDate: Vue.prototype.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
        modifiedDate: Vue.prototype.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
      };
      // console.log('33333 matching: true!!, ', state.drawers[drawerIndex - 1].columns[columnIndex - 1].spaces[
      //   spaceIndex - 1
      // ])
    }

    updateDrawerData(state, 'mark as matching END')
  },
  markSpaceAsSelected(
    state,
    { drawerIndex, columnIndex, spaceIndex, deselect = false }
  ) {
    if (deselect) state.selectedSpace = null;
    else if (drawerIndex && columnIndex && spaceIndex)
      state.selectedSpace = `${drawerIndex}-${columnIndex}-${spaceIndex}`;
  },
  setSpaceBadge(state, { drawerIndex, columnIndex, spaceIndex, option }) {
    state.drawers[drawerIndex - 1].columns[columnIndex - 1].spaces[
      spaceIndex - 1
    ] = {
      ...state.drawers[drawerIndex - 1].columns[columnIndex - 1].spaces[
      spaceIndex - 1
      ],
      badge: option,
    };
    updateDrawerData(state, 'setspacebadge');
  },
  addLabel(state, { label }) {
    state.labels.push(label);

    updateLabelsData(state);
  },
  deleteLabel(state, { label }) {
    let index = state.labels?.findIndex((thisLabel) => thisLabel === label);
    state.labels.splice(index, 1);

    updateLabelsData(state);
  },
  updateQueue(state, { queue }) {
    state.queue = queue;
  },
  deleteItemFromQueue(state, { id }) {
    deleteItemFromQueueCall(id);
  },
  clearQueue() {
    clearQueue();
  },
  addToRandomStackItemsLineItem(state, { drawerIndex, id, totalItems }) {
    state.drawers[drawerIndex - 1].randomStackItems.push({
      id,
      totalItems,
    });
    // TODO CHANGE STATS
  },
  toggleUpdatingRn(state, { bln }) {
    state.updatingRn = bln;
  },
};

export const actions = {
  setSpaceBadge({ commit }, { drawerIndex, columnIndex, spaceIndex, option }) {
    commit("setSpaceBadge", { drawerIndex, columnIndex, spaceIndex, option });
  },
  updateDrawers({ commit }, { drawers, place }) {
    console.log('update drawers: ', place);
    commit("updateDrawers", drawers);
  },
  addDrawer({ commit }) {
    commit("addDrawer");
  },
  deleteDrawer({ commit }, index) {
    const cnfrm = confirm(
      `Are you sure you want to delete drawer ${index}?`
    );
    if (!cnfrm) return;

    commit("deleteDrawer", index);
  },
  init({ commit }) {
    commit("init");
  },
  addColumn({ commit }, drawerIndex) {
    commit("addColumn", drawerIndex);
  },
  deleteColumn({ commit }, { drawerIndex, columnIndex }) {
    const cnfrm = confirm(
      `Are you sure you want to delete column ${columnIndex} (it belongs in drawer ${drawerIndex})?`
    );
    if (!cnfrm) return;

    commit("deleteColumn", { drawerIndex, columnIndex });
  },
  addSpace({ commit }, { drawerIndex, columnIndex, size }) {
    commit("addSpace", { drawerIndex, columnIndex, size });
  },
  deleteSpace({ commit }, { drawerIndex, columnIndex, spaceIndex, id }) {
    const cnfrm = confirm(
      drawerIndex && columnIndex
        ? `Are you sure you want to delete shelf ${spaceIndex} (belongs in drawer ${drawerIndex} and column ${columnIndex})?`
        : `Are you sure you want to delete item ${id}?`
    );
    if (!cnfrm) return;

    commit("deleteSpace", { drawerIndex, columnIndex, spaceIndex, id });
  },
  emptySpace({ commit }, { drawerIndex, columnIndex, spaceIndex }) {
    const cnfrm = confirm(
      `Are you sure you want to delete shelf ${spaceIndex} (it belongs in drawer ${drawerIndex} and column ${columnIndex})?`
    );
    if (!cnfrm) return;

    commit("emptySpace", { drawerIndex, columnIndex, spaceIndex });
  },
  addLineItemToSpace(
    { commit },
    { drawerIndex, columnIndex, spaceIndex, abort, id, lineItemId }
  ) {
    commit("addLineItemToSpace", {
      drawerIndex,
      columnIndex,
      spaceIndex,
      abort,
      id,
      lineItemId
    });
  },
  toggleErrorModalOpen({ commit }, bool) {
    commit("toggleErrorModalOpen", bool);
  },
  toggleLoginModalOpen({ commit }, bool) {
    commit("toggleLoginModalOpen", bool);
  },
  abortAny({ commit }, { drawerIndex, columnIndex, spaceIndex }) {
    if (drawerIndex && columnIndex && spaceIndex) {
      commit("addLineItemToSpace", {
        drawerIndex,
        columnIndex,
        spaceIndex,
        abort: true,
      });
    }
  },
  completeAny({ commit }, { drawerIndex, columnIndex, spaceIndex }) {
    if (drawerIndex && columnIndex && spaceIndex) {
      commit("addLineItemToSpace", {
        drawerIndex,
        columnIndex,
        spaceIndex,
      });
    }
  },
  clearAllMatching({ commit }) {
    commit("clearAllMatching");
  },
  clearAllCompleted({ commit }) {
    commit("clearAllCompleted");
  },
  undo({ commit }) {
    commit("undo");
  },
  changeErrorText({ commit }, text) {
    commit("changeErrorText", text);
  },
  setSpaceNonMatching(
    { commit },
    { drawerIndex, columnIndex, spaceIndex, id }
  ) {
    setTimeout(
      () =>
        commit("setSpaceNonMatching", {
          drawerIndex,
          columnIndex,
          spaceIndex,
          id,
        }),
      400
    );
  },
  setLoggedIn({ commit }, { username, token, refreshToken }) {
    commit("setLoggedIn", { username, token, refreshToken });
  },
  logout({ commit }) {
    commit("setLoggedIn", {
      username: null,
      token: null,
      refreshToken: null,
    });
  },
  markAsMatching(
    { commit },
    { id, totalItems, drawerIndex, columnIndex, spaceIndex }
  ) {
    commit("markAsMatching", {
      id,
      totalItems,
      drawerIndex,
      columnIndex,
      spaceIndex,
    });
  },
  markSpaceAsSelected(
    { commit },
    { drawerIndex, columnIndex, spaceIndex, deselect }
  ) {
    commit("markSpaceAsSelected", {
      drawerIndex,
      columnIndex,
      spaceIndex,
      deselect,
    });
  },
  addLabel({ commit }, { label }) {
    commit("addLabel", { label });
  },
  deleteLabel({ commit }, { label }) {
    commit("deleteLabel", { label });
  },
  updateQueue({ commit }, { queue }) {
    commit("updateQueue", { queue });
  },
  deleteItemFromQueue({ commit }, { id }) {
    commit("deleteItemFromQueue", { id });
  },
  clearQueue({ commit }) {
    commit("clearQueue");
  },
  addToRandomStackItemsMatching({ commit }, { drawerIndex, id, totalItems }) {
    commit("markAsMatching", {
      drawerIndex,
      id,
      totalItems,
    });
    // stats?
  },
  toggleUpdatingRn({ commit }, { bln }) {
    commit("toggleUpdatingRn", { bln });
  },
  // addToRandomStackItemsLineItem({ commit }, { drawerIndex, id, totalItems }) {
  //   commit("addToRandomStackItemsMatching");
  // },
};

export const myStore = new Vuex.Store({
  state: {
    drawers: defaultDrawers,
    previousStorage: null,
    lastSpace: "",
    errorText: "",
    errorModalOpen: false,
    loginModalOpen: false,
    loggedIn: false,
    username: false,
    stats: {
      date: null,
      lineItemsAdded: 0,
      ordersCompleted: 0,
    },
    labels: [],
    queue: [],
    previousStats: null,
    selectedSpace: null,
    updatingRn: false,
  },
  plugins: [updateLocalStorage],
  mutations,
  actions,
});
